import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/web-app/web-app/node_modules/.pnpm/next-intl@3.14.1_next@14.2.14_react@18.3.1/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/web-app/web-app/node_modules/.pnpm/next@14.2.14_@babel+core@7.25.7_@opentelemetry+api@1.7.0_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/web-app/web-app/node_modules/.pnpm/next@14.2.14_@babel+core@7.25.7_@opentelemetry+api@1.7.0_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["Avatar"] */ "/home/runner/work/web-app/web-app/packages/ds/dist/src/components/Avatar/Avatar.js");
;
import(/* webpackMode: "eager", webpackExports: ["Badge"] */ "/home/runner/work/web-app/web-app/packages/ds/dist/src/components/Badge/Badge.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/web-app/web-app/packages/webapp/components/Carousel/Carousel.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/web-app/web-app/packages/webapp/components/CategoryCard/assets/blue.webp");
;
import(/* webpackMode: "eager" */ "/home/runner/work/web-app/web-app/packages/webapp/components/CategoryCard/assets/cyan.webp");
;
import(/* webpackMode: "eager" */ "/home/runner/work/web-app/web-app/packages/webapp/components/CategoryCard/assets/hotpink.webp");
;
import(/* webpackMode: "eager" */ "/home/runner/work/web-app/web-app/packages/webapp/components/CategoryCard/assets/pink.webp");
;
import(/* webpackMode: "eager" */ "/home/runner/work/web-app/web-app/packages/webapp/components/CategoryCard/assets/yellow.webp");
;
import(/* webpackMode: "eager" */ "/home/runner/work/web-app/web-app/packages/webapp/components/CommunityCard/CommunityCard.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/web-app/web-app/packages/webapp/components/CookieOption/CookieOption.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Featured"] */ "/home/runner/work/web-app/web-app/packages/webapp/components/Featured/Featured.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/web-app/web-app/packages/webapp/components/Filters/Filters.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/web-app/web-app/packages/webapp/components/Hero/Hero.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/web-app/web-app/packages/webapp/components/Navbar/Navbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TrackingPixel"] */ "/home/runner/work/web-app/web-app/packages/webapp/components/TrackingPixel/TrackingPixel.tsx");
